
import { Options, Vue } from 'vue-class-component';

import GroupContainer from '@/components/GroupContainer.vue';
import Button from '@/components/Button.vue';

@Options({
  name: 'HR Admin',
  components: { GroupContainer, Button },
  props: ['userInfoData'],
  created() {
    this.$store.dispatch('getBannerUploadData');
  },
  computed: {
    bannerUploadData() {
      return this.$store.state.bannerUploadData || {};
    },
    bannerUploadAccess() {
      // banner upload is accessible only to HR admins with college access
      return this.bannerUploadData.isHRAdminWithCollegeAccess;
    },
  },
})
export default class Holidays extends Vue {}
