
import { Options, Vue } from 'vue-class-component';
import moment from 'moment';

import arrowSVG from '@/components/svg/SVGArrow.vue';

@Options({
  name: 'Leave Balance',
  components: {
    arrowSVG,
  },
  props: ['userInfoData'],
  computed: {
    balanceOverLimit() {
      let limitReached = false;
      Object.keys(this.userInfoData.leaveBalance).forEach((i) => {
        if (this.userInfoData.leaveBalance[i].projectedOverVacaHourCap === true) {
          limitReached = true;
        }
      });
      return limitReached;
    },
    lowercase() {
      let text;
      if (this.userInfoData) {
        text = this.userInfoData.leaves_value_type.toLowerCase();
      }
      return text || '';
    },
    projectedExists() {
      let exists = false;
      Object.keys(this.userInfoData.leaveBalance).forEach((i) => {
        if (this.userInfoData.leaveBalance[i].projected_balance) {
          exists = true;
        }
      });
      return exists;
    },
  },
  methods: {
    parseDate(date: string) {
      return moment.utc(date).format('MM-DD-YYYY');
    },
  },
})
export default class Holidays extends Vue {}
