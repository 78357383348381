
import { Options, Vue } from 'vue-class-component';

import plusSVG from '@/components/svg/SVGPlus.vue';

@Options({
  name: 'New Request Button',
  components: {
    plusSVG,
  },
  props: ['link'],
})
export default class NewRequestButton extends Vue {}
