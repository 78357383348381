/* eslint-disable camelcase */

import { Configuration } from '@azure/msal-browser';
import { createStore } from 'vuex';
import axios from 'axios';
import auth from '../utilities/azureAuth';
import router from '../router';

const url = `${process.env.VUE_APP_URL_PROTOCOL}://${process.env.VUE_APP_API_URL}/api/v1`;

const routes = {
  authenticate: `${url}/authenticate`,
  employee: `${url}/employee`,
  leaveTypes: `${url}/leave-types`,
  employeeTypes: `${url}/employee-types`,
  schedule: `${url}/schedule`,
  holidays: `${url}/holidays`,
  request: `${url}/request`,
  hours: `${url}/hours`,
  requests: `${url}/requests`,
  withdraw: `${url}/withdraw`,
  approve: `${url}/approve`,
  decline: `${url}/decline`,
  requestHistory: `${url}/request-history`,
  employees: `${url}/employees`,
  pendingRequests: `${url}/pending-requests`,
  processedRequests: `${url}/processed-requests`,
  reports: `${url}/admin-report-request`,
  bannerUpload: `${url}/banner-upload-request`,
  calendars: `${url}/calendars`,
  info: `${url}/info`,
};

// This function generates array data type to satisfy typescript requirements
// const getArrayType = () => {
//   const arrayType: any[] = [];
//   return arrayType;
// };

export default createStore({
  state: {
    token: null,
    userInfoData: null,
    userCalendarsData: null,
    // bannerUploadExceptionList: getArrayType(),
    bannerUploadData: null,
    lookUpUserInfoData: null,
    hrUserTabData: null,
    userLookUpData: null,
    leaveTypesData: null,
    employeeTypesData: null,
    leaveItemData: null,
    hoursData: null,
    userScheduleData: null,
    userHolidayData: null,
    userLeaveRequestsData: null,
    userLeaveHistoryData: null,
    employeePendingRequestsData: null,
    employeeProcessedRequestsData: null,
    employeesListData: null,
    checkScheduleData: null,
    checkUserData: null,
    calendarsData: null,
    failedNewRequest: false,
    error: null,
    success: null,
    warning: null,
    persistantAlert: null,
    packageVersion: process.env.PACKAGE_VERSION || '0',
    apiVersion: null || '0',
    msalConfig: {
      auth: {
        clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_TENANT_ID}`,
        postLogoutRedirectUri: '/login',
        redirectUri: '/',
      },
      cache: {
        cacheLocation: 'localStorage',
      },
      system: {
        allowRedirectInIframe: true,
      },
    } as Configuration,
    msalScopes: process.env.VUE_APP_SCOPES?.split(' ') || [],
  },
  mutations: {
    loadToken(state, token) {
      state.token = token;
    },
    updateUserInfo(state, userInfoData) {
      state.userInfoData = userInfoData;
    },
    updateUserCalendars(state, userCalendarsData) {
      state.userCalendarsData = userCalendarsData;
    },
    updateBannerUploadData(state, bannerUploadData) {
      state.bannerUploadData = bannerUploadData;
    },
    updateLookUpUserInfo(state, lookUpUserInfoData) {
      state.lookUpUserInfoData = lookUpUserInfoData;
    },
    updateHrUserTab(state, hrUserTabData) {
      state.hrUserTabData = hrUserTabData;
    },
    updateUserLookUp(state, userLookUpData) {
      state.userLookUpData = userLookUpData;
    },
    updateLeaveTypes(state, leaveTypesData) {
      state.leaveTypesData = leaveTypesData;
    },
    updateEmployeeTypes(state, employeeTypesData) {
      state.employeeTypesData = employeeTypesData;
    },
    updateLeaveItem(state, leaveItemData) {
      state.leaveItemData = leaveItemData;
    },
    updateHours(state, hoursData) {
      state.hoursData = hoursData;
    },
    updateUserSchedule(state, userScheduleData) {
      state.userScheduleData = userScheduleData;
    },
    updateUserHoliday(state, userHolidayData) {
      state.userHolidayData = userHolidayData;
    },
    updateUserLeaveRequests(state, userLeaveRequestsData) {
      state.userLeaveRequestsData = userLeaveRequestsData;
    },
    updateUserLeaveHistory(state, userLeaveHistoryData) {
      state.userLeaveHistoryData = userLeaveHistoryData;
    },
    updateEmployeesList(state, employeesListData) {
      state.employeesListData = employeesListData;
    },
    updateEmployeePendingRequests(state, employeePendingRequestsData) {
      state.employeePendingRequestsData = employeePendingRequestsData;
    },
    updateEmployeeProcessedRequests(state, employeeProcessedRequestsData) {
      state.employeeProcessedRequestsData = employeeProcessedRequestsData;
    },
    updateCheckSchedule(state, checkScheduleData) {
      state.checkScheduleData = checkScheduleData;
    },
    updateCheckUser(state, checkUserData) {
      state.checkUserData = checkUserData;
    },
    updateCalendars(state, calendarsData) {
      state.calendarsData = calendarsData;
    },
    updateFailedNewRequest(state, failedNewRequest) {
      state.failedNewRequest = failedNewRequest;
    },
    updateSuccess(state, success) {
      state.success = success;
    },
    updateError(state, error) {
      state.error = error;
    },
    updateWarning(state, warning) {
      state.warning = warning;
    },
    updatePersistantAlert(state, persistantAlert) {
      state.persistantAlert = persistantAlert;
    },
    updateInfo(state, info) {
      state.apiVersion = info.api_version;
    },
    resetError(state) {
      state.error = null;
    },
    resetSuccess(state) {
      state.success = null;
    },
    resetWarning(state) {
      state.warning = null;
    },
    resetPersistantAlert(state) {
      state.persistantAlert = null;
    },
    clearLeaveItem(state) {
      state.leaveItemData = null;
    },
    clearEmployeeData(state) {
      state.lookUpUserInfoData = null;
      state.employeePendingRequestsData = null;
      state.employeeProcessedRequestsData = null;
    },
    resetToken(state) {
      state.token = null;
      state.userInfoData = null;
      state.lookUpUserInfoData = null;
      state.hrUserTabData = null;
      state.userLookUpData = null;
      state.leaveTypesData = null;
      state.employeeTypesData = null;
      state.leaveItemData = null;
      state.hoursData = null;
      state.userScheduleData = null;
      state.userHolidayData = null;
      state.userLeaveRequestsData = null;
      state.userLeaveHistoryData = null;
      state.employeePendingRequestsData = null;
      state.employeeProcessedRequestsData = null;
      state.employeesListData = null;
      state.checkScheduleData = null;
      state.checkUserData = null;
      // state.error = null;
      // state.success = null;
      // state.warning = null;
      // state.persistantAlert = null;
    },
  },
  actions: {
    info({ commit }) {
      const config = {
        headers: {
          // 'x-access-token': state.token,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      axios.get(routes.info, config)
        .then((response) => {
          commit('updateInfo', response.data);
        }).catch((error) => {
          commit('updateInfo', null);
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    async userLogin({ state }) {
      const token = await auth.authenticate(state.msalConfig, state.msalScopes, true);
      if (token) {
        router.push('/');
      }
    },
    async userLogout({ state }, { onRedirectNavigate }) {
      await auth.logout(state.msalConfig, onRedirectNavigate);
    },
    userInfo({ commit, state }, { uin }) {
      let employeeURL;
      if (uin) {
        employeeURL = `${routes.employee}?uin=${uin}`;
      } else {
        employeeURL = routes.employee;
      }
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      axios.get(employeeURL, config)
        .then((response) => {
          if (uin) {
            commit('updateLookUpUserInfo', response.data);
          } else {
            commit('updateUserInfo', response.data);
          }
        }).catch((error) => {
          if (uin) {
            commit('updateLookUpUserInfo', { error: error.response.data.error });
          } else {
            commit('updateUserInfo', { error: error.response.data.error });
          }
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    userLeaveTypes({ commit, state }, { uin }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      let leaveTypeURL;
      if (uin) {
        leaveTypeURL = `${routes.leaveTypes}?uin=${uin}`;
      } else {
        leaveTypeURL = routes.leaveTypes;
      }
      axios.get(leaveTypeURL, config)
        .then((response) => {
          commit('updateLeaveTypes', response.data);
        }).catch((error) => {
          commit('updateLeaveTypes', null);
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    userEmployeeTypes({ commit, state }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      axios.get(routes.employeeTypes, config)
        .then((response) => {
          commit('updateEmployeeTypes', response.data);
        }).catch((error) => {
          commit('updateEmployeeTypes', null);
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    userSchedule({ commit, state }, { uin, all }) {
      let scheduleURL;
      if (uin && all !== true) {
        scheduleURL = `${routes.schedule}?uin=${uin}`;
      } else if (uin && all === true) {
        scheduleURL = `${routes.schedule}?uin=${uin}&all`;
      } else {
        scheduleURL = routes.schedule;
      }
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      axios.get(scheduleURL, config)
        .then((response) => {
          commit('updateUserSchedule', response.data);
        }).catch((error) => {
          commit('updateUserSchedule', null);
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    submitSchedule({ commit, state, dispatch }, {
      uin,
      days,
      startDate,
      check,
    }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      const data = {
        sunday: days[0].hours,
        monday: days[1].hours,
        tuesday: days[2].hours,
        wednesday: days[3].hours,
        thursday: days[4].hours,
        friday: days[5].hours,
        saturday: days[6].hours,
        start_date: startDate,
      };
      const urlSubmitSchedule = check ? `${routes.schedule}?uin=${uin}&check=true` : `${routes.schedule}?uin=${uin}`;
      axios.post(urlSubmitSchedule, data, config)
        .then((response) => {
          if (check) {
            commit('updateCheckSchedule', response.data.success);
          } else if (!check) {
            router.push('/hr-admin');
            commit('updateSuccess', 'Successfully added schedule.');
          }
        }).catch((error) => {
          if (error) {
            commit('updateError', error.response.data.error);
          }
          dispatch('redirectLogin');
        });
    },
    updateSchedule({ commit, state, dispatch }, {
      uin,
      days,
      uuid,
      check, // Parameter only used to determine URL in axios call; to check leave conflicts
    }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      const data = {
        sunday: days[0].hours,
        monday: days[1].hours,
        tuesday: days[2].hours,
        wednesday: days[3].hours,
        thursday: days[4].hours,
        friday: days[5].hours,
        saturday: days[6].hours,
        uuid,
      };
      const urlUpdateSchedule = check ? `${routes.schedule}?uin=${uin}&check=true` : `${routes.schedule}?uin=${uin}`;
      axios.put(urlUpdateSchedule, data, config)
        .then((response) => {
          if (check) {
            commit('updateCheckSchedule', response.data.success);
          } else if (!check) {
            router.push('/hr-admin');
            commit('updateSuccess', 'Successfully updated schedule.');
          }
        }).catch((error) => {
          if (error) {
            commit('updateError', error.response.data.error);
          }
          dispatch('redirectLogin');
        });
    },
    submitUser({ commit, state, dispatch },
      {
        uin,
        status,
        primarySupervisorUin,
        secondarySupervisorUin,
        appointment,
        holidayGroup,
        check,
      }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      let data = {};
      if (secondarySupervisorUin === '') {
        data = {
          supervisor_uin: primarySupervisorUin,
          appointment,
          status,
          holiday_group: holidayGroup,
        };
      } else {
        data = {
          supervisor_uin: primarySupervisorUin,
          alternate_supervisor_uin: secondarySupervisorUin,
          appointment,
          status,
          holiday_group: holidayGroup,
        };
      }
      const urlSubmitUser = check ? `${routes.employee}?uin=${uin}&check=true` : `${routes.employee}?uin=${uin}`;
      axios.post(urlSubmitUser, data, config)
        .then((response) => {
          if (check) {
            commit('updateCheckUser', response.data.success);
          } else if (!check) {
            router.push('/hr-admin');
            commit('updateSuccess', response.data);
          }
        }).catch((error) => {
          if (error) {
            commit('updateError', error.response.data.error);
          }
          dispatch('redirectLogin');
        });
    },
    updateUser({ commit, state, dispatch },
      {
        uin,
        status,
        primarySupervisorUin,
        secondarySupervisorUin,
        appointment,
        holidayGroup,
        check,
      }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      let data = {};
      if (secondarySupervisorUin === '') {
        data = {
          supervisor_uin: primarySupervisorUin,
          appointment,
          status,
          holiday_group: holidayGroup,
        };
      } else {
        data = {
          supervisor_uin: primarySupervisorUin,
          alternate_supervisor_uin: secondarySupervisorUin,
          appointment,
          status,
          holiday_group: holidayGroup,
        };
      }
      const urlUpdateUser = check ? `${routes.employee}?uin=${uin}&check=true` : `${routes.employee}?uin=${uin}`;
      axios.put(urlUpdateUser, data, config)
        .then((response) => {
          if (check) {
            commit('updateCheckUser', response.data.success);
          } else if (!check) {
            router.push('/hr-admin');
            commit('updateSuccess', response.data);
          }
        }).catch((error) => {
          if (error) {
            commit('updateError', error.response.data.error);
          }
          dispatch('redirectLogin');
        });
    },
    userHoliday({ commit, state }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      axios.get(routes.holidays, config)
        .then((response) => {
          commit('updateUserHoliday', response.data);
        }).catch((error) => {
          commit('updateUserHoliday', null);
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    hours({ commit, state, dispatch },
      {
        startDate,
        endDate,
        durationType,
        durationHours,
        type,
        uin, // optional, used by HR Admins
      }) {
      const params: {
        start_date: string,
        end_date: string,
        duration_type: string,
        duration_hours: number,
        type: string,
        uin?: string,
      } = {
        start_date: startDate,
        end_date: endDate,
        duration_type: durationType,
        duration_hours: durationHours,
        type,
      };
      if (uin) {
        params.uin = uin;
      }
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
        params,
      };
      axios.get(routes.hours, config)
        .then((response) => {
          commit('updateHours', response.data);
        }).catch((error) => {
          commit('updateHours', null);
          if (error) {
            commit('updateError', error.response.data.error);
          }
          dispatch('redirectLogin');
        });
    },
    newRequestSubmit({ commit, state, dispatch },
      {
        startDate,
        endDate,
        durationType,
        durationHours,
        type,
        comment,
        uin, // optional, used by HR Admins
      }) {
      const data: {
        start_date: string,
        end_date: string,
        duration_type: string,
        duration_hours: number,
        type: string,
        comment: string,
        uin?: string,
      } = {
        start_date: startDate,
        end_date: endDate,
        duration_type: durationType,
        duration_hours: durationHours,
        type,
        comment,
      };
      if (uin) {
        data.uin = uin;
      }
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      axios.post(routes.request, data, config)
        .then((response) => {
          commit('updateSuccess', response.data);
          if (uin) {
            commit('updateHrUserTab', 'leaves');
            dispatch('userInfo', { uin });
          } else {
            router.push('/');
            dispatch('userInfo', { uin: null });
          }
        }).catch((error) => {
          if (error) {
            commit('updateFailedNewRequest', true);
            commit('updateError', error.response.data.error);
          }
          dispatch('redirectLogin');
        });
    },
    leaveItem({ commit, state }, { uuid }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      axios.get(`${routes.request}?uuid=${uuid}`, config)
        .then((response) => {
          commit('updateLeaveItem', response.data);
        }).catch((error) => {
          commit('updateLeaveItem', null);
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    updateRequest({ commit, state, dispatch },
      {
        uuid,
        durationType,
        durationHours,
        type,
        comment,
      }) {
      const data: {
        duration_type: string,
        duration_hours: number,
        type: string,
        comment: string,
      } = {
        duration_type: durationType,
        duration_hours: durationHours,
        type,
        comment,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      axios.put(`${routes.request}?uuid=${uuid}`, data, config)
        .then((response) => {
          commit('updateSuccess', response.data);
          router.push('/');
        }).catch((error) => {
          if (error) {
            commit('updateError', error.response.data.error);
          }
          dispatch('redirectLogin');
        });
    },
    userLeaveRequests({ commit, state }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      axios.get(routes.requests, config)
        .then((response) => {
          commit('updateUserLeaveRequests', response.data);
        }).catch((error) => {
          commit('updateUserLeaveRequests', []);
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    userLeaveHistory({ commit, state }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      axios.get(routes.requestHistory, config)
        .then((response) => {
          commit('updateUserLeaveHistory', response.data);
        }).catch((error) => {
          commit('updateUserLeaveHistory', []);
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    withdrawRequest({ commit, state, dispatch }, { uuid }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      axios.put(`${routes.withdraw}?uuid=${uuid}`, undefined, config)
        .then((response) => {
          commit('updateSuccess', response.data);
          if (router.currentRoute.value.name === 'Home') {
            // Update all requests from database
            dispatch('userLeaveRequests');
            dispatch('userLeaveHistory');
            dispatch('userInfo', { uin: null });
          } else {
            // Redirect to home page
            router.push('/');
          }
        }).catch((error) => {
          if (error) {
            commit('updateError', error.response.data.error);
          }
          dispatch('redirectLogin');
        });
    },
    deleteDeclined({ commit, state, dispatch }, { uuid }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      axios.delete(`${routes.request}?uuid=${uuid}`, config)
        .then((response) => {
          commit('updateSuccess', response.data);
          if (router.currentRoute.value.name === 'Home') {
            // Update all requests from database
            dispatch('userLeaveRequests');
            dispatch('userLeaveHistory');
            dispatch('userInfo', { uin: null });
          } else if (router.currentRoute.value.name === 'Leave Item') {
            // Redirect to home page
            router.push('/');
          } else if (router.currentRoute.value.name === 'View/Edit User') {
            if (!router.currentRoute.value.params.uuid) {
              // Update all requests from database
              dispatch('employeePendingRequests', { uin: router.currentRoute.value.params.uin });
              dispatch('employeeProcessedRequests', { uin: router.currentRoute.value.params.uin });
              dispatch('userInfo', { uin: router.currentRoute.value.params.uin });
            } else {
              router.push(`/hr-admin/user/${router.currentRoute.value.params.uin}`);
              commit('updateHrUserTab', 'leaves');
            }
          }
        }).catch((error) => {
          if (error) {
            commit('updateError', error.response.data.error);
          }
          dispatch('redirectLogin');
        });
    },
    employeesList({ commit, state, dispatch }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      axios.get(routes.employees, config)
        .then((response) => {
          commit('updateEmployeesList', response.data);
        }).catch((error) => {
          commit('updateEmployeesList', []);
          if (error) {
            commit('updateError', error.response.data.error);
          }
          dispatch('redirectLogin');
        });
    },
    employeePendingRequests({ commit, state }, { uin }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      axios.get(`${routes.pendingRequests}?uin=${uin}`, config)
        .then((response) => {
          commit('updateEmployeePendingRequests', response.data);
        }).catch((error) => {
          commit('updateEmployeePendingRequests', []);
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    employeeProcessedRequests({ commit, state }, { uin }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      axios.get(`${routes.processedRequests}?uin=${uin}`, config)
        .then((response) => {
          commit('updateEmployeeProcessedRequests', response.data);
        }).catch((error) => {
          commit('updateEmployeeProcessedRequests', []);
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    approveRequest({ commit, state, dispatch }, { uuids, reviewerComment, uin }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      const data = {
        uuids,
        comment: reviewerComment,
      };
      axios.put(routes.approve, data, config)
        .then((response) => {
          commit('updateSuccess', response.data);
          // Clicked action button from Manage Single Employee page
          if (router.currentRoute.value.name === 'Manage Single Employee') {
            // Update all requests from database
            dispatch('employeePendingRequests', uin);
            dispatch('employeeProcessedRequests', uin);
            dispatch('userInfo', uin);
          // Clicked action button from Leave Item page
          } else if (router.currentRoute.value.name === 'Employee Leave Item') {
            // Redirect to home page
            const tempUIN = { uin: router.currentRoute.value.params.uin };
            router.push(`/manage-employees/${tempUIN.uin}`);
            dispatch('employeePendingRequests', tempUIN);
            dispatch('employeeProcessedRequests', tempUIN);
          } else if (router.currentRoute.value.name === 'View/Edit User') { // HR ADMIN
            if (!router.currentRoute.value.params.uuid) { // HR ADMIN NOT LEAVE ITEM PAGE
              // Update all requests from database
              dispatch('employeePendingRequests', uin);
              dispatch('employeeProcessedRequests', uin);
              dispatch('userInfo', uin);
            } else {
              router.push(`/hr-admin/user/${router.currentRoute.value.params.uin}`);
              commit('updateHrUserTab', 'leaves');
            }
          }
        }).catch((error) => {
          if (error) {
            commit('updateError', error.response.data.error);
          }
          dispatch('redirectLogin');
        });
    },
    declineRequest({ commit, state, dispatch }, { uuids, reviewerComment, uin }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          // 'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      };
      const data = {
        uuids,
        comment: reviewerComment,
      };
      axios.put(routes.decline, data, config)
        .then((response) => {
          commit('updateSuccess', response.data);
          if (router.currentRoute.value.name === 'Manage Single Employee') {
            // Update all requests from database
            dispatch('employeePendingRequests', uin);
            dispatch('employeeProcessedRequests', uin);
            dispatch('userInfo', uin);
          } else if (router.currentRoute.value.name === 'Employee Leave Item') {
            // Redirect to home page
            const tempUIN = { uin: router.currentRoute.value.params.uin };
            router.push(`/manage-employees/${tempUIN.uin}`);
            dispatch('employeePendingRequests', tempUIN);
            dispatch('employeeProcessedRequests', tempUIN);
          } else if (router.currentRoute.value.name === 'View/Edit User') {
            if (!router.currentRoute.value.params.uuid) {
              // Update all requests from database
              dispatch('employeePendingRequests', uin);
              dispatch('employeeProcessedRequests', uin);
              dispatch('lookUpUser', uin);
            } else {
              router.replace(`/hr-admin/user/${uin}`);
              commit('updateHrUserTab', 'leaves');
            }
          }
        }).catch((error) => {
          if (error) {
            commit('updateError', error.response.data.error);
          }
          dispatch('redirectLogin');
        });
    },
    submitHRAdminReportRequest({ commit, state }, {
      startDate, endDate, reportType, uinList, employeeTypes,
    }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          'Content-Type': 'application/json',
        },
      };
      const data = {
        report_type: reportType,
        start_date: startDate,
        end_date: endDate,
        employee_uins: uinList,
        ...(employeeTypes && {
          employee_types: employeeTypes,
        }),
      };
      axios.post(routes.reports, data, config)
        .then((response) => {
          commit('updateSuccess', response.data.success);
        })
        .catch((error) => {
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    submitBannerUploadRequest({ commit, state }, data) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          'Content-Type': 'application/json',
        },
      };

      axios.post(routes.bannerUpload, data, config)
        .then((response) => {
          commit('updateSuccess', response.data.success);
        })
        .catch((error) => {
          if (error) {
            commit('updateError', error.response.data.error);
          }
        }).finally(() => {
          router.push('/hr-admin');
        });
    },
    getBannerUploadData({ commit, state }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          'Content-Type': 'application/json',
        },
      };

      axios.get(routes.bannerUpload, config)
        .then((response) => {
          commit('updateBannerUploadData', response.data);
        })
        .catch((error) => {
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    getCalendars({ state, commit }, { uin }) {
      interface Properties {
        uin?: string,
      }
      const params: Properties = {
        uin,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          'Content-Type': 'application/json',
        },
        params,
      };
      axios.get(routes.calendars, config)
        .then((response) => {
          if (uin) {
            commit('updateCalendars', response.data);
          } else {
            commit('updateUserCalendars', response.data);
          }
        })
        .catch((error) => {
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    addCalendars({ state, commit }, { uin, calendars }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          'Content-Type': 'application/json',
        },
      };
      interface Properties {
        uin: string,
        calendars: Array<string>,
      }
      const data: Properties = {
        uin,
        calendars,
      };
      return axios.post(routes.calendars, data, config)
        .catch((error) => {
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    removeCalendars({ state, commit }, { uin, calendars }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          'Content-Type': 'application/json',
        },
        data: {
          uin,
          calendars,
        },
      };
      axios.delete(routes.calendars, config)
        .catch((error) => {
          if (error) {
            commit('updateError', error.response.data.error);
          }
        });
    },
    verifyUser({ state }, { uin }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          'Content-Type': 'application/json',
        },
      };
      return axios.get(`${routes.employee}?uin=${uin}`, config);
    },
    verifyCalendar({ state }, { uin, calendars, check }) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
          'Content-Type': 'application/json',
        },
      };
      interface Properties {
        uin: string,
        calendars: Array<string>,
        check: boolean,
      }
      const data: Properties = {
        uin,
        calendars,
        check,
      };
      return axios.post(routes.calendars, data, config);
    },
    async redirectLogin({ commit, state }) {
      try {
        const token = await auth.authenticate(state.msalConfig, state.msalScopes, false);
        if (token === undefined) {
          commit('resetToken');
          router.push('/login');
        }
      } catch (_) {
        console.log('There was a problem authenticating. Please try again');
      }
    },
  },
  // modules: {
  // },
  getters: {
    appVersion(state) {
      return state.packageVersion;
    },
  },
});

/* eslint-enable camelcase */
