
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'Group Container',
  components: {},
  props: ['heading', 'description'],
  data() {
    return {};
  },
})
export default class GroupContainer extends Vue {}
