/* eslint-disable max-len */
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
  Configuration,
} from '@azure/msal-browser';

// eslint-disable-next-line consistent-return
async function authenticate(
  msalConfig: Configuration,
  scopes: Array<string>,
  redirect: boolean,
): Promise<string | undefined> {
  const msalInstance = new PublicClientApplication(msalConfig);

  try {
    const redirectResponse = await msalInstance.handleRedirectPromise();
    if (redirectResponse !== null) {
      return redirectResponse.accessToken;
    }
  } catch (error) {
    return undefined;
  }

  const accounts = msalInstance.getAllAccounts();
  const account = accounts[0];
  if (account) {
    try {
      const response = await msalInstance.acquireTokenSilent({ scopes, account });
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        if (redirect) {
          msalInstance.acquireTokenRedirect({ scopes });
          return undefined;
        }
        return undefined;
      }
    }
  }

  if (redirect) {
    msalInstance.acquireTokenRedirect({ scopes });
    return undefined;
  }

  return undefined;
}

function logout(
  msalConfig: Configuration,
  onRedirectNavigate: ((url: string) => boolean | void) | undefined,
): void {
  const msalInstance = new PublicClientApplication(msalConfig);

  const accounts = msalInstance.getAllAccounts();
  const account = accounts[0];
  msalInstance.logoutRedirect({ account, ...(onRedirectNavigate && { onRedirectNavigate }) });
}

export default {
  authenticate,
  logout,
};
