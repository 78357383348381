
import { Options, Vue } from 'vue-class-component';

// SVGs
import circleLogoSVG from '@/components/svg/logo-circle.svg';

@Options({
  name: 'AppHeader',
  props: ['userInfoData'],
  data() {
    return {
      accountStatusText: 'Login',
      circleLogoSVG,
      menuOpen: false,
    };
  },
  computed: {
    employeesListData() {
      return this.$store.state.employeesListData;
    },
  },
  methods: {
    toggleMenu() {
      if (this.$store.state.token) {
        this.menuOpen = !this.menuOpen;
      } else {
        this.menuOpen = false; // Need to close the menu after logout
        this.$store.commit('updateWarning', 'Please log in to continue');
      }
    },
  },
})
export default class AppHeader extends Vue {}
