import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import auth from './utilities/azureAuth';
import './registerServiceWorker';
import router from './router';
import store from './store';

// Check if authenticated
router.beforeEach(async (to, from, next) => {
  // skip authentication check for logout page
  if (to.name === 'Logout') {
    next();
    return;
  }
  try {
    const token = await auth.authenticate(store.state.msalConfig, store.state.msalScopes, false);
    if (token === undefined) {
      store.commit('resetToken');
      if (to.name === 'Login') {
        next();
      } else {
        next({ name: 'Login' });
      }
    } else {
      store.commit('loadToken', token);
      if (to.name === 'Login') {
        next({ name: 'Home' });
      } else {
        next();
      }
    }
  } catch (err) {
    console.log('There was a problem authenticating. Please try again');
  }
});

createApp(App)
  .use(VueGtag, { config: { bootstrap: false } })
  .use(store)
  .use(router)
  .mount('#app');
