
import { Options, Vue } from 'vue-class-component';

import GroupContainer from '@/components/GroupContainer.vue';
import Button from '@/components/Button.vue';

@Options({
  name: 'Login',
  components: { GroupContainer, Button },
  methods: {
    userLogin() {
      this.$store.dispatch('userLogin');
    },
  },
})
export default class Login extends Vue {}
