
import { Options, Vue } from 'vue-class-component';
import moment from 'moment';

import GroupContainer from '@/components/GroupContainer.vue';
import DateRange from '@/components/DateRange.vue';
import Button from '@/components/Button.vue';

@Options({
  name: 'New Request',
  components: { GroupContainer, DateRange, Button },
  inheritAttrs: false,
  props: [
    'uin',
    'isHRAdminReadOnly',
  ],
  data() {
    return {
      workday: false,
      allowHalfDayType: true,
      allowCustomType: true,
      exception: false,
      summaryScreen: false,
      totalHours: 0,
      totalLeaves: 0,
      conflict: false,
      newRequest: {
        start_date: null,
        end_date: null,
        duration_type: null,
        hours: null,
        minutes: null,
        duration_hours: null,
        type: null,
        comment: null,
      },
      submitted: false,
    };
  },
  created() {
    this.$store.dispatch('userInfo', this.payload(this.uin));
    this.$store.dispatch('userLeaveTypes', this.payload(this.uin));
    this.$store.dispatch('userSchedule', this.payload(this.uin, false));
    this.$store.dispatch('userHoliday');
  },
  computed: {
    leaveTypesData() {
      return this.$store.state.leaveTypesData;
    },
    userInfoData() {
      let userData;
      if (this.uin) {
        userData = this.$store.state.lookUpUserInfoData;
      } else {
        userData = this.$store.state.userInfoData;
      }
      return userData;
    },
    userScheduleData() {
      return this.$store.state.userScheduleData;
    },
    userHolidayData() {
      return this.$store.state.userHolidayData;
    },
    hoursData() {
      return this.$store.state.hoursData;
    },
    failedNewRequest() {
      return this.$store.state.failedNewRequest;
    },
  },
  methods: {
    payload(uin: string, all: boolean) { // Used for userInfo and userSchedule
      const config: {
        uin?: string,
        all?: boolean,
      } = {};
      if (uin) {
        config.uin = uin;
      }
      if (all) {
        config.all = all;
      }
      return config;
    },
    parseDurationHours() {
      if (this.newRequest.duration_type === 'custom') {
        const hours = parseInt(this.newRequest.hours, 10);
        const minutes = this.newRequest.minutes / 60;
        this.newRequest.duration_hours = hours + minutes;
      } else {
        this.newRequest.duration_hours = null;
      }
    },
    hours() {
      this.summaryScreen = true;
      this.parseDurationHours();
      const payload: {
        startDate: string,
        endDate: string,
        durationType: string,
        durationHours: number,
        type: string,
        uin?: string,
      } = {
        startDate: this.newRequest.start_date,
        endDate: this.newRequest.end_date,
        durationType: this.newRequest.duration_type,
        durationHours: this.newRequest.duration_hours,
        type: this.newRequest.type,
      };
      if (this.uin) {
        payload.uin = this.uin;
      }
      this.$store.dispatch('hours', payload);
    },
    async newRequestSubmit() {
      this.submitted = true;
      this.parseDurationHours();
      const payload: {
        startDate: string,
        endDate: string,
        durationType: string,
        durationHours: number,
        type: string,
        comment: string,
        uin?: string,
      } = {
        startDate: this.newRequest.start_date,
        endDate: this.newRequest.end_date,
        durationType: this.newRequest.duration_type,
        durationHours: this.newRequest.duration_hours,
        type: this.newRequest.type,
        comment: this.newRequest.comment,
      };
      if (this.uin) {
        payload.uin = this.uin;
      }
      try {
        this.$store.dispatch('newRequestSubmit', payload);
      } catch (error) {
        this.submitDisable = false;
      }
    },
    cancel() {
      this.summaryScreen = false;
    },
    // Recieve Start Date value from Date Range component
    startDateChanged(newDate: string) {
      this.newRequest.start_date = newDate;
    },
    // Recieve End Date value from Date Range component
    endDateChanged(newDate: string) {
      this.newRequest.end_date = newDate;
    },
    workdayCheck() {
      this.$store.dispatch('userHoliday');
      this.$store.dispatch('userSchedule', this.payload);
      let holiday = false;
      let i;
      // parse schedule data
      const schedule = Object.values(this.userScheduleData);
      // get day of week from user start date selection
      // fiy: schedule endpoint first and second array item is uuid and uin, hense '+ 2'.
      const dow = moment(this.newRequest.start_date).day() + 2;
      // check if selected is holiday
      for (i = 0; i < this.userHolidayData.length; i += 1) {
        if (this.newRequest.start_date === moment(this.userHolidayData[i].date).format('YYYY-MM-DD')) {
          holiday = true;
        }
      }
      // check if weekend or holiday is selected
      // for workday to apply end date should match start date
      if ((schedule[dow] === 0 || holiday)
      && this.newRequest.start_date === this.newRequest.end_date) {
        this.workday = true;
      } else {
        this.workday = false;
      }
    },
    leaveChange() {
      switch (this.newRequest.type) {
        case 'floating_holiday':
        case 'parental_leave':
          this.newRequest.duration_type = 'full_day';
          this.allowHalfDayType = false;
          this.allowCustomType = false;
          break;
        case 'compensable_sick':
        case 'plfa':
        case 'plfc':
          this.allowHalfDayType = true;
          this.allowCustomType = true;
          break;
        case 'fmla_float_holiday':
        case 'fmla_parental_leave':
        case 'fmla_vessa_float_holiday':
        case 'vessa_float_holiday':
        case 'fba_float_holiday':
        case 'cba_float_holiday':
          this.newRequest.duration_type = 'full_day';
          this.allowHalfDayType = false;
          this.allowCustomType = false;
          this.warningMessagePreapproved(this.newRequest.type);
          break;
        case 'fmla_vacation':
        case 'fmla_sick':
        case 'fmla_no_pay':
        case 'accommodation_vacation':
        case 'accommodation_sick':
        case 'accommodation_no_pay':
        case 'vessa_vacation':
        case 'vessa_sick':
        case 'vessa_no_pay':
        case 'fmla_vessa_sick':
        case 'fmla_vessa_vacation':
        case 'fmla_vessa_no_pay':
        case 'military_no_pay':
        case 'workers_comp_no_pay':
        case 'fba_vacation':
        case 'fba_sick':
        case 'fba_no_pay':
        case 'adm':
        case 'od1':
        case 'od2':
        case 'cba_vacation':
        case 'cba_sick':
        case 'cba_unpaid':
        case 'cba_holiday':
        case 'dvl':
        case 'ftl':
        case 'ftd':
        case 'ftu':
        case 'shbn':
          this.allowHalfDayType = true;
          this.allowCustomType = true;
          this.warningMessagePreapproved(this.newRequest.type);
          break;
        default:
          this.allowHalfDayType = true;
          this.allowCustomType = this.exception;
      }
      this.userWithDays();
    },
    userWithDays() {
      if (this.userInfoData) {
        switch (this.userInfoData.leaves_value_type) {
          case 'Days':
            this.allowHalfDayType = false;
            this.allowCustomType = false;
            this.newRequest.duration_type = 'full_day';
            break;
          default:
            break;
        }
      }
    },
    parseLeaveTypes(type: string) {
      let typeParsed = '';
      for (let i = 0; i < this.leaveTypesData.length; i += 1) {
        if (this.leaveTypesData[i].type === type) {
          typeParsed = this.leaveTypesData[i].display_title;
        }
      }
      return typeParsed;
    },
    warningMessagePreapproved(type: any) {
      this.$store.commit(
        'updateWarning',
        `${this.parseLeaveTypes(type)} must be pre-approved by your department Human Resources before submitting the request.`,
      );
    },
    parseDurationType(type: string) {
      let parsed;
      switch (type) {
        case 'full_day':
          parsed = 'Full Day';
          break;
        case 'half_day':
          parsed = 'Half Day';
          break;
        case 'custom':
          parsed = 'Custom';
          break;
        default:
          parsed = 'Error';
      }
      return parsed;
    },
    wholeNumber(event: any) {
      if (event.key === '.' || event.key === ',') {
        event.preventDefault();
      }
    },
  },
  watch: {
    userInfoData() {
      if (this.userInfoData.type === 'nonExempt') {
        this.exception = true;
        this.leaveChange();
      }
      this.userWithDays();
    },
    'newRequest.start_date': {
      handler(newVal, oldVal) {
        // prevent end date value being null or less than start date
        if (newVal > this.newRequest.end_date
        || this.newRequest.end_date === null) {
          this.newRequest.end_date = newVal;
        }
        // check if selected date allows for workday type
        // this.workdayCheck(); // Temporarily disablin
      },
    },
    'newRequest.type': {
      handler(newVal, oldVal) {
        if (this.exception === false
        && newVal !== 'compensable_sick'
        && newVal !== 'fmla_vacation'
        && newVal !== 'fmla_sick'
        && newVal !== 'fmla_no_pay'
        && newVal !== 'accommodation_vacation'
        && newVal !== 'accommodation_sick'
        && newVal !== 'accommodation_no_pay'
        && newVal !== 'vessa_vacation'
        && newVal !== 'vessa_sick'
        && newVal !== 'vessa_no_pay'
        && newVal !== 'fmla_vessa_sick'
        && newVal !== 'fmla_vessa_vacation'
        && newVal !== 'fmla_vessa_no_pay'
        && newVal !== 'military_no_pay'
        && newVal !== 'workers_comp_no_pay'
        && newVal !== 'fba_vacation'
        && newVal !== 'fba_sick'
        && newVal !== 'fba_no_pay'
        && newVal !== 'plfa'
        && newVal !== 'adm'
        && newVal !== 'od1'
        && newVal !== 'od2'
        && newVal !== 'cba_vacation'
        && newVal !== 'cba_sick'
        && newVal !== 'cba_unpaid'
        && newVal !== 'cba_holiday'
        && newVal !== 'dvl'
        && newVal !== 'ftl'
        && newVal !== 'ftd'
        && newVal !== 'ftu'
        && newVal !== 'shbn'
        && newVal !== 'plfc'
        && this.newRequest.duration_type === 'custom') {
          switch (oldVal) {
            case 'compensable_sick':
            case 'fmla_vacation':
            case 'fmla_sick':
            case 'fmla_no_pay':
            case 'accommodation_vacation':
            case 'accommodation_sick':
            case 'accommodation_no_pay':
            case 'vessa_vacation':
            case 'vessa_sick':
            case 'vessa_no_pay':
            case 'fmla_vessa_sick':
            case 'fmla_vessa_vacation':
            case 'fmla_vessa_no_pay':
            case 'military_no_pay':
            case 'workers_comp_no_pay':
            case 'fba_vacation':
            case 'fba_sick':
            case 'fba_no_pay':
            case 'plfa':
            case 'adm':
            case 'od1':
            case 'od2':
            case 'cba_vacation':
            case 'cba_sick':
            case 'cba_unpaid':
            case 'cba_holiday':
            case 'dvl':
            case 'ftl':
            case 'ftd':
            case 'ftu':
            case 'shbn':
            case 'plfc':
              this.newRequest.duration_type = 'full_day';
              break;
            default:
              break;
          }
        }
      },
      deep: true,
    },
    hoursData(val) {
      if (val && val.some((e: any) => e.conflict === true)) {
        this.conflict = true;
      } else {
        this.conflict = false;
      }
      let hours = 0;
      let leaves = 0;
      for (let i = 0; i < this.hoursData.length; i += 1) {
        hours += this.hoursData[i].hours;
        if (this.hoursData[i].conflict === false) {
          leaves += 1;
        }
      }
      this.totalHours = hours;
      this.totalLeaves = leaves;
    },
    failedNewRequest(newVal, oldVal) {
      if (newVal === true) {
        this.submitDisable = true;
        this.$store.commit('updateFailedNewRequest', false);
      } else if (newVal === false) {
        this.submitDisable = false;
      }
    },
  },
  beforeRouteLeave(to, from) {
    let resolve;
    if (this.summaryScreen === true && this.submitted !== true) {
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!');
      if (!answer) {
        resolve = false;
      } else {
        resolve = true;
      }
    }
    return resolve;
  },
})
export default class NewRequest extends Vue {}
