
import { Options, Vue } from 'vue-class-component';

import arrowSVG from '@/components/svg/SVGArrow.vue';

@Options({
  name: 'AccordionTitle',
  components: {
    arrowSVG,
  },
  props: ['title', 'toggle'],
  methods: {
    updateToggle() {
      this.$emit('updateToggle', !this.toggle);
    },
  },
})
export default class AccordionTitle extends Vue {}
