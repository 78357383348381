
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/Button.vue';
import DateRange from '@/components/DateRange.vue';
import RadioInput from '@/components/RadioInput.vue';
import List from '@/components/List.vue';
import SelectList from '@/components/SelectList.vue';

@Options({
  name: 'Report Form',
  components: {
    DateRange,
    Button,
    RadioInput,
    List,
    SelectList,
  },
  props: ['reportData'],
  data() {
    return {
      startDate: null,
      endDate: null,
      // eslint-disable-next-line dot-notation
      radioInputValue: this.reportData['radioInputDefaultValue'],
      uinList: [],
      selectedEmployeeType: null,
    };
  },
  computed: {
    userEmployeeTypes() {
      return this.$store.state.employeeTypesData;
    },
  },
  methods: {
    startDateChanged(newDate: string) {
      this.startDate = newDate;
    },
    endDateChanged(newDate: string) {
      this.endDate = newDate;
    },
    radioInputChanged(value: string) {
      this.radioInputValue = value;
      if (value === 'all') {
        this.uinList = [];
        this.selectedEmployeeType = null;
      } else if (value === 'type') {
        this.uinList = [];
      } else if (value === 'selected') {
        this.selectedEmployeeType = null;
      }
    },
    uinListChanged(list: Array<string>) {
      this.uinList = list;
    },
    selectListChanged(type: Record<string, string>) {
      this.selectedEmployeeType = type;
    },
    submitHRAdminReportRequest(reportType: string) {
      let startDate = null;
      let endDate = null;
      let { uinList } = this;
      const { selectedEmployeeType } = this;
      const employeeTypeList = [];
      if (
        reportType === 'leave_summary'
        || reportType === 'leave_details'
        || reportType === 'compensable_sick_leave_details'
      ) {
        startDate = this.startDate;
        endDate = this.endDate;
      }
      if (
        reportType === 'leave_details'
        && this.radioInputValue === 'selected'
      ) {
        if (uinList.length === 0) {
          this.$store.commit('updateError', 'Add UIN(s) to the list.');
          return;
        }
        uinList = uinList.map((user: any) => user.uin);
      }
      if (
        reportType === 'leave_details'
        && this.radioInputValue === 'type'
      ) {
        // If employee type is not choosen, throw error
        if (!selectedEmployeeType) {
          this.$store.commit('updateError', 'Please select a employee type');
          return;
        }
        employeeTypeList.push(selectedEmployeeType.employee_type);
      }
      this.$store.dispatch('submitHRAdminReportRequest', {
        startDate,
        endDate,
        reportType: uinList.length === 0 ? reportType : `${reportType}_scoped`,
        uinList: uinList.length === 0 ? null : uinList.join(','),
        employeeTypes: employeeTypeList.length === 0 ? null : employeeTypeList,
      });
      if (uinList.length !== 0) {
        this.uinList = [];
      }
      this.selectListChanged(null);
      this.startDateChanged(null);
      this.endDateChanged(null);
    },
  },
})
export default class ReportForm extends Vue {}

