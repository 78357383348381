
import { Options, Vue } from 'vue-class-component';
import moment from 'moment';

import GroupContainer from '@/components/GroupContainer.vue';

@Options({
  name: 'Holidays',
  components: { GroupContainer },
  created() {
    this.$store.dispatch('userHoliday');
  },
  computed: {
    userHolidayData() {
      return this.$store.state.userHolidayData;
    },
  },
  methods: {
    formatDate(datetime: Date) {
      const newDatetime = moment.utc(datetime, moment.ISO_8601, true).format('MM/DD/YYYY');
      return newDatetime;
    },
    // requestWorkday(data: any) {
    //   console.log(data);
    // },
    parseGroup(val: string) {
      let text = '';
      switch (val) {
        case 'groupA':
          text = 'Group A';
          break;
        case 'groupB':
          text = 'Group B';
          break;
        case 'groupA,groupB':
          text = 'Group A/B';
          break;
        default:
          break;
      }
      return text;
    },
  },
})
export default class Holidays extends Vue {}
