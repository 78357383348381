
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'Date Range',
  components: {},
  props: ['startDate', 'endDate', 'startDateChange', 'endDateChange', 'startDateRequired', 'endDateRequired'],
  data() {
    return {
      startDateInternal: null,
      endDateInternal: null,
    };
  },
  created() {
    if (this.startDateInternal === null || this.endDateInternal === null) {
      this.startDateInternal = this.startDate;
      this.endDateInternal = this.endDate;
    }
  },
  watch: {
    startDate(newDate, oldVal) {
      this.startDateInternal = newDate;
    },
    startDateInternal(newDate) {
      this.$emit('start-date-changed', newDate);
    },
    endDate(newDate) {
      this.endDateInternal = newDate;
    },
    endDateInternal(newDate) {
      this.$emit('end-date-changed', newDate);
    },
  },
})
export default class DateRange extends Vue {}
