
import { Options, Vue } from 'vue-class-component';
import plusSVG from '@/components/svg/SVGPlus.vue';

import Button from '@/components/Button.vue';

@Options({
  name: 'List',
  components: { plusSVG, Button },
  props: ['header', 'description', 'maxListLength', 'list', 'required', 'listType', 'listTypeName', 'calendarUIN', 'disabled'],
  data() {
    return {
      current: '',
      maxListLengthParsed: parseInt(this.maxListLength, 10),
      listInternal: this.list,
    };
  },
  watch: {
    list: {
      deep: true,
      handler(list) {
        this.listInternal = list;
      },
    },
    listInternal: {
      deep: true,
      handler(list) {
        this.$emit('list-changed', list);
      },
    },
  },
  methods: {
    add() {
      const value = this.current;
      // let parsedValue:number;
      // if (this.listType === 'number') {
      //   parsedValue = parseInt(value, 10);
      //   console.log(parsedValue);
      // }
      // console.log(this.listInternal);
      if (this.listInternal.length >= this.maxListLengthParsed) {
        this.$store.commit(
          'updateError',
          `Maximum ${this.listTypeName}s allowed in the list is ${this.maxUINListLengthParsed}`,
        );
        return;
      }
      // console.log(`yes ${value}`);
      // console.log(this.listInternal);
      if (this.listInternal.some((item: any) => (item.uin || item) === value)) {
        // console.log('yes');
        this.$store.commit('updateError', `${this.listTypeName} already present in the list.`);
        return;
      }
      switch (this.listTypeName) {
        case 'UIN':
          this.uin(value);
          break;
        case 'Calendar':
          this.calendar(value.toLowerCase(), this.calendarUIN);
          break;
        default:
          this.$store.commit('updateError', 'Something went wrong.');
          break;
      }
    },
    uin(uin: string) {
      const nineDigitsPattern = /^[0-9]{9}$/;
      if (!uin.match(nineDigitsPattern)) {
        this.$store.commit('updateError', 'UIN must be 9 characters long.');
        return;
      }
      this.$store
        .dispatch('verifyUser', { uin })
        .then((response: any) => {
          const { name } = response.data;
          this.listInternal.push({ uin, name });
          this.current = '';
        })
        .catch((error: any) => {
          if (error) {
            this.$store.commit('updateError', error.response.data.error);
          }
        });
    },
    calendar(calendar: string, uin: string) {
      const characterCheck = /^(\S+)(@uic.edu)$/;
      const payload = {
        uin,
        calendars: [calendar],
        check: true,
      };
      // const check = () => { this.$store.dispatch('addCalendars', payload); };
      // check()
      this.$store
        .dispatch('verifyCalendar', payload)
        .then((response: any) => {
          console.log(response);
          if (calendar.match(characterCheck)) {
            this.listInternal.push(calendar);
            this.current = '';
          } else {
            this.$store.commit('updateError', 'Calendar must be a \'@uic.edu\' address.');
          }
        })
        .catch((error: any) => {
          if (error) {
            this.$store.commit('updateError', error.response.data.error);
          }
        });
    },
    remove(value: string) {
      // console.log(value);
      if (this.disabled) {
        return;
      }
      const filteredList = this.listInternal.filter(
        (item: any) => (item.uin || item) !== value,
      );
      this.listInternal = filteredList;
    },
  },
})
export default class List extends Vue {}
