
import { Options, Vue } from 'vue-class-component';
import plusSVG from '@/components/svg/SVGPlus.vue';
import List from '@/components/List.vue';

import GroupContainer from '@/components/GroupContainer.vue';
import Button from '@/components/Button.vue';

@Options({
  name: 'BannerUpload',
  created() {
    if (!this.$store.state.bannerUploadData) {
      this.$store.dispatch('getBannerUploadData');
    }
  },
  components: {
    GroupContainer,
    Button,
    plusSVG,
    List,
  },
  data() {
    return {
      state: 'checklist', // 'checklist', 'summary'
      checklist: {
        audit: false,
        validateOnly: true,
      },
      filter: {
        employeeType: 'all', // hardcoding 'all', commented out employeeType field from form
      },
      exceptions: {
        uin: [],
      },
      summary: {
        confirm: false,
      },
    };
  },
  computed: {
    bannerUploadData() {
      return this.$store.state.bannerUploadData || {};
    },
    summaryBtnText() {
      if (this.checklist.validateOnly) {
        return 'Confirm & Start Validation';
      }
      return 'Confirm & Start Final Upload';
    },
    validateOnlyText() {
      if (this.checklist.validateOnly) {
        return 'Enabled';
      }
      return 'Disabled';
    },
    isCheckListBtnDisabled() {
      const today = new Date();
      const { all = {}, apFaculty = {}, csExempt = {} } = this.bannerUploadData;
      let uploadStartDate;
      let uploadEndDate;

      switch (this.filter.employeeType) {
        case 'all': uploadStartDate = new Date(all.uploadStartDate);
          uploadEndDate = new Date(all.uploadEndDate);
          break;
        case 'ap_faculty': uploadStartDate = new Date(apFaculty.uploadStartDate);
          uploadEndDate = new Date(apFaculty.uploadEndDate);
          break;
        case 'cs_exempt': uploadStartDate = new Date(csExempt.uploadStartDate);
          uploadEndDate = new Date(csExempt.uploadEndDate);
          break;
        default: uploadStartDate = new Date(1); uploadEndDate = new Date(1);
      }
      const tooEarly = today.getTime() < uploadStartDate.getTime();
      const tooLate = today.getTime() > uploadEndDate.getTime();
      const notAllowed = tooEarly || tooLate;
      const auditNotSelected = !this.checklist.audit;
      const employeeGroupEmpty = this.filter.employeeType === '';
      return auditNotSelected || employeeGroupEmpty || notAllowed;
    },

    isSummaryBtnDisabled() {
      if (this.checklist.validateOnly) {
        return false;
      }
      return !this.summary.confirm;
    },
  },
  methods: {
    submitRequest() {
      const uinExceptions = this.exceptions.uin.map((user: { uin: number }) => user.uin);
      this.$store.dispatch('submitBannerUploadRequest', {
        validate_only: this.checklist.validateOnly,
        employee_exceptions: uinExceptions.join(','),
        employee_groups: this.filter.employeeType,
      });
    },
    uinListChanged(list: Array<string>) {
      this.exceptions.uin = list;
    },
  },
})
export default class BannerUpload extends Vue {}
