
import { Options, Vue } from 'vue-class-component';
import GroupContainer from '@/components/GroupContainer.vue';
import ReportForm from '@/components/ReportForm.vue';

@Options({
  name: 'Reports',
  components: { GroupContainer, ReportForm },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch('userEmployeeTypes');
  },
  computed: {
    reportsData() {
      return {
        leaveSummaryReportData: {
          reportType: 'leave_summary',
          heading: 'Leave Summary Report',
          description:
            'This report contains leave details for each user grouped by leave types. On request submission, the report will be mailed to you within the next 1 hour',
          showDateControls: true,
          startDateRequired: true,
          endDateRequired: true,
          buttonAriaLabel: 'Submit Leave Summary Report Request',
          formAriaLabel: 'Leave Summary Report Request Submission',
          buttonText: 'Submit Report Request',
          buttonType: 'submit',
        },
        leaveDetailsReportData: {
          reportType: 'leave_details',
          heading: 'Leave Details Report',
          description:
            'This report contains leave details for each user. On request submission, the report will be mailed to you within the next 1 hour',
          showAdditionalControls: true,
          radioInputPrimaryLabel: 'Employee Scope',
          radioInputSecondaryLabel:
            "Select the scope of employees for the report. 'All Employees' will generate a report for all employees within your college, 'Employee Type' will generate a report for a choosen employee type within your college and 'Selected Employee' will generate a report for employees added by UIN..",
          radioInputOptions: [
            { title: 'All Employees', value: 'all' },
            { title: 'Employee Type', value: 'type' },
            { title: 'Selected Employees', value: 'selected' },
          ],
          radioInputDefaultValue: 'all',
          uinListHeader: 'Employee UIN',
          uinListDescription:
            'Add the UIN of the employee you would like a report for. Only one UIN is allowed per single employee report.',
          selectListHeader: 'Employee Type',
          selectListDescription:
            'Select the employee type you would like a report for.',
          uinListMaxLength: 10,
          showDateControls: true,
          startDateRequired: true,
          endDateRequired: true,
          buttonAriaLabel: 'Submit Leave Details Report Request',
          formAriaLabel: 'Leave Details Report Request Submission',
          buttonText: 'Submit Report Request',
          buttonType: 'submit',
        },
        employeeReportData: {
          reportType: 'user',
          heading: 'Employee Report',
          description:
            'This report contains the employee details under your organization. On request submission, the report will be mailed to you within the next 1 hour',
          showDateControls: false,
          startDateRequired: false,
          endDateRequired: false,
          buttonAriaLabel: 'Submit Employee Report Request',
          formAriaLabel: 'Employee Report Request Submission',
          buttonText: 'Submit Report Request',
          buttonType: 'submit',
        },
        pendingLeaveDetailsReportData: {
          reportType: 'pending_leave_details',
          heading: 'Pending Leave Details Report',
          description:
            'This report contains pending leave details for each user. On request submission, the report will be mailed to you within the next 1 hour',
          showDateControls: false,
          startDateRequired: false,
          endDateRequired: false,
          buttonAriaLabel: 'Submit Pending Leave Details Report Request',
          formAriaLabel: 'Pending Leave Details Report Request Submission',
          buttonText: 'Submit Report Request',
          buttonType: 'submit',
        },
        conflictLeaveDetailsReportData: {
          reportType: 'conflict_leave_details',
          heading: 'Conflict Leave Details Report',
          description:
            'This report contains conflict leave details for each user. On request submission, the report will be mailed to you within the next 1 hour',
          showDateControls: false,
          startDateRequired: false,
          endDateRequired: false,
          buttonAriaLabel: 'Submit Conflict Leave Details Report Request',
          formAriaLabel: 'Conflict Leave Details Report Request Submission',
          buttonText: 'Submit Report Request',
          buttonType: 'submit',
        },
        compensableSickLeaveDetailsReportData: {
          reportType: 'compensable_sick_leave_details',
          heading: 'Compensable Sick Leave Details Report',
          description:
            'This report contains compensable sick leave details for each user. On request submission, the report will be mailed to you within the next 1 hour',
          showDateControls: true,
          startDateRequired: true,
          endDateRequired: true,
          buttonAriaLabel:
            'Submit Compensable Sick Leave Details Report Request',
          formAriaLabel:
            'Compensable Sick Leave Details Report Request Submission',
          buttonText: 'Submit Report Request',
          buttonType: 'submit',
        },
        negativeLeaveBalanceReportData: {
          reportType: 'negative_leave_balance',
          heading: 'Negative Leave Balance Report',
          description:
            'This report contains negative leave balance details for each user. On request submission, the report will be mailed to you within the next 1 hour',
          showDateControls: false,
          startDateRequired: false,
          endDateRequired: false,
          buttonAriaLabel: 'Submit Negative Leave Balance Report Request',
          formAriaLabel: 'Negative Leave Balance Report Request Submission',
          buttonText: 'Submit Report Request',
          buttonType: 'submit',
        },
      };
    },
  },
})
export default class Reports extends Vue {}
