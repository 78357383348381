import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '../views/Login.vue';
import Home from '../views/Home.vue';
import ManageEmployees from '../views/ManageEmployees.vue';
import ManageSingleEmployee from '../views/ManageSingleEmployee.vue';
import NewRequest from '../views/NewRequest.vue';
import LeaveItem from '../views/LeaveItem.vue';
import LeaveBalance from '../views/LeaveBalance.vue';
import Holidays from '../views/Holidays.vue';
import HRAdmin from '../views/HRAdmin.vue';
import User from '../views/User.vue';
import Reports from '../views/Reports.vue';
import BannerUpload from '../views/BannerUpload.vue';
import Logout from '../views/Logout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    components: {
      default: Home,
    },
  },
  {
    path: '/leave-item/:uuid',
    name: 'Leave Item',
    components: {
      default: LeaveItem,
    },
  },
  {
    path: '/manage-employees',
    name: 'Manage Employees',
    components: {
      default: ManageEmployees,
    },
  },
  {
    path: '/manage-employees/:uin',
    name: 'Manage Single Employee',
    components: {
      default: ManageSingleEmployee,
    },
    children: [
      {
        path: '/manage-employees/:uin/leave-item/:uuid',
        name: 'Employee Leave Item',
        component: LeaveItem,
      },
      {
        path: '/manage-employees/:uin/leave-balance',
        name: 'Employee Leave Balance',
        component: LeaveBalance,
      },
    ],
  },
  // {
  //   path: '/manage-employees/:uin/leave-item/:uuid',
  //   name: 'Employee Leave Item',
  //   components: {
  //     default: LeaveItem,
  //   },
  // },
  {
    path: '/new-request',
    name: 'New Leave Request',
    components: {
      default: NewRequest,
    },
  },
  {
    path: '/leave-balance',
    name: 'Leave Balance',
    components: {
      default: LeaveBalance,
    },
  },
  {
    path: '/holidays',
    name: 'Holidays',
    components: {
      default: Holidays,
    },
  },
  {
    path: '/hr-admin',
    name: 'HR Admin',
    components: {
      default: HRAdmin,
    },
  },
  {
    path: '/hr-admin/add-user',
    name: 'Add User',
    components: {
      default: User,
    },
  },
  {
    path: '/hr-admin/user/:uin?/:uuid?',
    name: 'View/Edit User',
    components: {
      default: User,
    },
  },
  {
    path: '/hr-admin/reports',
    name: 'Reports',
    components: {
      default: Reports,
    },
  },
  {
    path: '/hr-admin/banner-upload',
    name: 'Banner Upload',
    components: {
      default: BannerUpload,
    },
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      default: Login,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  // {
  //   path: '/404',
  //   name: '404',
  //   components: {
  //     default: 404,
  //   },
  // },
  {
    path: '/logout',
    name: 'Logout',
    components: {
      default: Logout,
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
