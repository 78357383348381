
import { BrowserUtils } from '@azure/msal-browser';
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'Logout',
  created() {
    this.$store.dispatch('userLogout', {
      // Required for front channel logout, if false server sign out is skipped
      onRedirectNavigate: () => !BrowserUtils.isInIframe(),
    });
  },
})
export default class Logout extends Vue {}
