
import { Options, Vue } from 'vue-class-component';

import AccordionTitle from '@/components/AccordionTitle.vue';
import Button from '@/components/Button.vue';

@Options({
  name: 'Multi Select List',
  props: [
    'title',
    'userLeaveList',
    'formatDate',
    'withdrawRequest',
    'deleteDeclined',
    'approveRequest',
    'declineRequest',
    'hrViewLeave',
    'userInfoData',
    'isHRAdminReadOnly',
  ],
  components: {
    AccordionTitle,
    Button,
  },
  data() {
    return {
      selectAllState: false,
      listToggle: true,
      reviewerComment: null,
      leavesArray: [],
      sumHours: {
        Vacation: 1,
        Sick: 2,
        Floating: 1,
      },
    };
  },
  methods: {
    toggleCheckboxLeave(event: any, uuid: string) {
      if (this.leavesArray.includes(uuid) && event.target.checked === false) {
        const index = this.leavesArray.indexOf(uuid);
        this.leavesArray.splice(index, 1);
      } else if (!this.leavesArray.includes(uuid) && event.target.checked === true) {
        this.leavesArray.push(uuid);
      }
    },
    selectAll(event: any) {
      for (let i = 0; i < this.userLeaveList.length; i += 1) {
        this.userLeaveList[i].boolean = event.target.checked;
        this.toggleCheckboxLeave(event, this.userLeaveList[i].uuid);
      }
    },
    parseStatusTitle(status: string) {
      let parsed;
      switch (status) {
        case 'pending-approval':
          parsed = 'Pending Approval';
          break;
        case 'pending-withdrawal':
          parsed = 'Pending Withdrawal';
          break;
        default:
          parsed = status;
      }
      return parsed;
    },
    parseDurationType(type: string, hours: number) {
      let parsed;
      switch (type) {
        case 'full_day':
          parsed = `Full Day (${hours} Hours)`;
          break;
        case 'half_day':
          parsed = `Half Day (${hours} Hours)`;
          break;
        case 'custom':
          parsed = `Custom (${hours} Hours)`;
          break;
        default:
          parsed = 'Error';
      }
      return parsed;
    },
  },
})
export default class MultiSelectList extends Vue {}
