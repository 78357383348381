
import { Options, Vue } from 'vue-class-component';

import AccordionTitle from '@/components/AccordionTitle.vue';
import Button from '@/components/Button.vue';

@Options({
  name: 'User Info',
  components: {
    AccordionTitle,
    Button,
  },
  props: ['userInfoData', 'userCalendarsData'],
  data() {
    return {
      userToggle: false,
      balanceToggle: true,
    };
  },
  methods: {
    leaveBalanceLink() {
      switch (this.$route.name) {
        case 'Manage Single Employee':
        case 'Employee Leave Balance':
          return `/manage-employees/${this.$route.params.uin}/leave-balance`;
        case 'View/Edit User': {
          // Check if component is in logged in user or looked up user scope
          let slug = '';
          if (this.$parent.$options.name === 'GoTime') {
            slug = '/leave-balance';
          }
          return slug;
        }
        default:
          return '/leave-balance';
      }
    },
    ifHRAdminLink() {
      let clickEvent = null;
      if (this.$route.name === 'View/Edit User'
      && this.$parent.$options.name !== 'GoTime') {
        clickEvent = this.$store.commit('updateHrUserTab', 'leave-balance');
      }
      return clickEvent;
    },
  },
  watch: {
    '$route.fullPath': {
      immediate: true,
      handler() {
        if (this.$route.name === 'Manage Employees'
        || this.$route.name === 'Leave Item'
        || this.$route.name === 'Leave Balance'
        || this.$route.name === 'HR Admin'
        || this.$route.name === 'Add User'
        || this.$route.name === 'View/Edit User'
        || this.$route.name === 'Reports') {
          this.balanceToggle = false;
        } else {
          this.balanceToggle = true;
        }
      },
    },
  },
})
export default class UserInfo extends Vue {}
