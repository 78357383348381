
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'Breadcrumbs',
  computed: {
    lookUpUserInfoData() {
      return this.$store.state.lookUpUserInfoData;
    },
    managedEmployeesPage() {
      const truthy = this.$route.path.includes('manage-employees');
      return truthy;
    },
  },
  methods: {
    checkEmployee() {
      const payload = {
        uin: this.$route.params.uin,
      };
      this.$store.dispatch('userInfo', { uin: this.$route.params.uin });
    },
  },
  watch: {
    '$route.name': {
      immediate: true,
      handler(val) {
        if (val === 'Manage Single Employee'
        || val === 'Employee Leave Item') {
          this.checkEmployee();
        }
      },
    },
  },
})
export default class Breadcrumbs extends Vue {}
