
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'Manage Employees',
  data() {
    return {
      empty1: [
        'You are all caught up, sit back and relax.',
        'Looks like all your employees are keeping busy.',
      ],
      empty2: [
        'Your employees are keeping you busy.',
        'Looks like all your employees need you.',
      ],
    };
  },
  created() {
    this.$store.dispatch('employeesList');
  },
  computed: {
    employeesListData() {
      return this.$store.state.employeesListData;
    },
    noRequests() {
      let noRequests = 0;
      const employee = this.employeesListData;
      for (let i = 0; i < employee.length; i += 1) {
        if (employee[i].pending_requests > 0) {
          noRequests += 1;
        }
      }
      return noRequests;
    },
  },
  methods: {
    randomizeMessage(array: Array<string>) {
      return array[Math.floor(Math.random() * array.length)];
    },
  },
})
export default class ManageEmployee extends Vue {}
