<template>
  <div class="radio-input-container">
    <div class="radio-input-label-container">
      <strong>{{ primaryLabel }}</strong>
      <span class="asterisk">*</span>
      <div>{{ secondaryLabel }}</div>
    </div>

    <div class="radio-input-options">
      <label v-for="option in options" :key="option.value">
        <input
          class="gotime-checkbox"
          v-model="radioInputValueInternal"
          :aria-label="option.title"
          :value="option.value"
          :required="required"
          name="radio-input"
          type="radio"
        />
        {{ option.title }}
      </label>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'RadioInput',
  components: {},
  props: [
    'radioInputValue',
    'primaryLabel',
    'secondaryLabel',
    'required',
    'options',
  ],
  data() {
    return {
      radioInputValueInternal: this.radioInputValue,
    };
  },
  watch: {
    radioInputValue(value) {
      this.radioInputValueInternal = value;
    },
    radioInputValueInternal(value) {
      this.$emit('radio-input-changed', value);
    },
  },
})
export default class RadioInput extends Vue {}
</script>

<style lang="scss" scoped>
.radio-input {
  &-container {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
  }
  &-label-container {
    width: 100%;
  }
  &-options {
    margin-bottom: 0px;
    margin-top: 8px;
    margin-inline-end: 16px;
    label {
      display: flex;
      align-items: center;
      margin: 0;
    }
    input {
      margin-bottom: 0px;
      margin-inline-end: 16px;
    }
  }
}
</style>
