
import { Options, Vue } from 'vue-class-component';

import plusSVG from '@/components/svg/SVGPlus.vue';

@Options({
  name: 'Error',
  props: ['error'],
  components: { plusSVG },
})
export default class Alert extends Vue {}
