
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'SelectList',
  props: ['header', 'description', 'selectData'],
  data() {
    return {
      currentSelection: null,
      selectedValueInternal: this.currentSelection,
    };
  },
  watch: {
    selectedValue: {
      deep: true,
      handler(newVal) {
        this.currentSelection = newVal;
      },
    },
    selectedValueInternal: {
      deep: true,
      handler(newVal) {
        this.$emit('select-list-changed', newVal);
      },
    },
  },
})
export default class SelectList extends Vue {}
